import { Navigate, useLocation } from 'react-router-dom';
import { APICore } from '../helpers/api/apiCore';

type PrivateRouteProps = {
    component: React.ComponentType,
    roles?: string,
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    let location = useLocation();
    const api = new APICore();
    const loggedInUser = api.getLoggedInUser();

    if (api.isUserAuthenticated() === false) {
        return <Navigate to={'/account/login'} state={{ from: location }} replace />;
    }

    // Si 'roles' est défini, vérifiez si l'utilisateur a un rôle autorisé
    if (roles && !roles.split(',').some(role => loggedInUser.role.includes(role.trim()))) {
        // Si aucun des rôles de l'utilisateur n'est autorisé, redirigez vers la page d'accueil
        return <Navigate to={{ pathname: '/' }} />;
    }

    return <RouteComponent />;
};


export default PrivateRoute;
